import { TRANSACTION_PULLDOWN_STATES, TRANSACTION_STATUS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { NavItemType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { NO_ACCOUNT } from '../reducers/accounts';
import { AppContextType, UserType } from '../ts-types/AppTypes';
import { CLEARANCE_URL } from '../utils/setup-endpoints';
import { PolicyTransactionTypes } from './Submission';
import { CANCELLATION_TYPES } from './TransactionConstants';
import { showSubmissionPolicyTerms, showSubmissionPricing } from '../utils/submission-helpers';
import { Types } from '../ts-types/viki-types';
import { AUTHORITY_GROUP_LEVELS } from '../hooks/authority-group-level';

export const NAVIGATION_LOCATIONS = {
    OVERVIEW: {
        NAME: 'submissions/overview',
        PATH: '/submissions/:accountId/:submissionId/overview',
        FIELDS_TO_REPLACE: ['accountId', 'submissionId'],
    },
    COVERAGE_OPTION: {
        NAME: 'submission/underwriting',
        PATH: '/submissions/:accountId/:submissionId/underwriting/:quoteId?',
        FIELDS_TO_REPLACE: ['accountId', 'submissionId', 'quoteId'],
    },
    TRANSACTION: {
        NAME: 'submission/transactions',
        PATH: '/submissions/:accountId/transactions/:transactionId?',
        FIELDS_TO_REPLACE: ['accountId', 'transactionId'],
    },
};

export const POLICY_TERM_SECTION_IDS = {
    Overview: 'OVERVIEW',
    'Shared and Layered': 'SHARED_AND_LAYERED',
    'Occurrence Limit of Liability': 'OCCURRENCE_LIMIT_OF_LIABILITY',
    Wind: 'WIND',
    'Pro-rata Wind MEP': 'PRO_RATA_WIND_MEP',
    Flood: 'FLOOD',
    Earthquake: 'EARTHQUAKE',
    AOP: 'AOP',
    'Business Interruption': 'BUSINESS_INTERRUPTION',
    'Claims Valuation and Coinusrance': 'CLAIMS_VALUATION_AND_COINSURANCE',
    Margin: 'MARGIN',
    Terror: 'TERROR',
    'Equipment Breakdown': 'EQUIPMENT_BREAKDOWN',
    'Ord & Law': 'ORDINANCE_OR_LAW',
    Sinkhole: 'SINKHOLE',
    'Property Enhancement Endorsement': 'PROPERTY_ENHANCEMENT_ENDORSEMENT',
} as const;

export const showCoverageOptions = (appContext: AppContextType): boolean => {
    const { currentSubmission, currentTransaction, currentAccount } = appContext;
    const transactionType = currentTransaction && currentTransaction.transaction_type;

    if (!currentAccount || !currentSubmission?.SUPPLEMENTED) {
        return null;
    }

    return !!(
        currentAccount.id === NO_ACCOUNT ||
        ([PolicyTransactionTypes.NEW_BUSINESS, PolicyTransactionTypes.RENEWAL_BUSINESS].includes(transactionType) &&
            currentSubmission &&
            currentSubmission.is_editable)
    );
};

const hasSubmissionInfo = (appContext: AppContextType) => {
    const { currentSubmissionId, currentAccountId } = appContext;
    return !!(currentSubmissionId || currentAccountId);
};

const usingPolicyCoverage = (props: any) => {
    return !!props.featureFlags.policy_coverage && location.pathname.includes('policy_terms');
};

export const INITIAL_NAVIGATION = (
    v1BaseUrl: string
): NavItemType<{
    policyTermsSectionIds: Record<string, string>;
    featureFlags: Types.FeatureFlags;
    tenantFlags: Types.TenantFlags;
    me: UserType;
    appContext: AppContextType;
}>[] => {
    return [
        {
            name: 'Analytics',
            sameTab: true,
            url: '/analytics',
            visible: props => {
                return props.featureFlags.analytics && props.tenantFlags.analytics;
            },
        },
        {
            name: 'Cattools+ (Beta)',
            sameTab: true,
            url: '/cattools',
            visible: props => {
                return props.me && props.tenantFlags.cattools;
            },
        },
        {
            name: 'Bulk Cat Model',
            sameTab: true,
            url: '/bulk-cat-model',
            visible: props => {
                return props.me && props.tenantFlags.bulk_cat_model && props.me.authority_group_level === AUTHORITY_GROUP_LEVELS[4];
            },
        },
        {
            name: 'Clearance',
            isExternal: true,
            sameTab: true,
            url: CLEARANCE_URL,
            visible: !!CLEARANCE_URL,
        },
        {
            name: 'Submissions',
            visible: true,
            url: `/submissions`,
            urlOverride: `/?selected=:submissionId`,
            navProps: {
                transactionPullDown: TRANSACTION_PULLDOWN_STATES.HIDDEN,
            },
            subNavItems: [
                {
                    visible: props => hasSubmissionInfo(props.appContext),
                    name: 'Overview',
                    url: '/:submissionSlug/overview',
                },
                { visible: props => hasSubmissionInfo(props.appContext), name: 'Documents', url: '/:submissionSlug/documents' },
                { visible: props => hasSubmissionInfo(props.appContext), name: 'Tasks', url: '/:submissionSlug/tasks' },
                {
                    name: 'Buildings',
                    url: '/:submissionSlug/editor',
                    subItemIdGetter: 'buildingId',
                    visible: props => hasSubmissionInfo(props.appContext),
                },
                {
                    name: 'Claims',
                    url: '/:submissionSlug/claims',
                    visible: props => hasSubmissionInfo(props.appContext) && props.tenantFlags.claims,
                },
                {
                    name: 'Exposure Summary',
                    visible: ({ appContext: { currentSubmission }, tenantFlags }) => {
                        return tenantFlags.exposure_summary && currentSubmission?.has_buildings;
                    },
                    url: '/:submissionSlug/exposures',
                },
                {
                    name: 'Transactions',
                    visible: props => {
                        return showCoverageOptions(props.appContext) === false;
                    },
                    subItemIdGetter: 'transactionId',
                    url: '/:accountId/transactions',
                    navProps: {
                        transactionPullDown: TRANSACTION_PULLDOWN_STATES.HIDDEN,
                    },
                    subNavItems: [
                        {
                            name: 'Policy Terms',
                            url: '/:transactionId/policy_terms',
                            visible: ({ appContext: { currentSubmission, currentTransaction } }) => {
                                if (!currentSubmission) {
                                    return false;
                                }
                                return showSubmissionPolicyTerms(currentTransaction);
                            },
                        },
                        {
                            name: 'Pricing',
                            url: '/:transactionId/pricing',
                            visible: ({ appContext: { currentSubmission, currentTransaction }, tenantFlags }) => {
                                if (!currentSubmission) {
                                    return false;
                                }
                                return tenantFlags.pricing && showSubmissionPricing(currentSubmission.account_purpose, currentTransaction);
                            },
                        },
                        {
                            name: 'Building Premiums',
                            url: '/:transactionId/premiums',
                            visible: props => props.tenantFlags.building_premiums,
                        },
                        {
                            name: 'TIV Guidelines',
                            url: '/:transactionId/guidelines',
                            visible: ({ appContext: { currentTransaction }, tenantFlags }) => {
                                return (
                                    tenantFlags.tiv_guidelines &&
                                    currentTransaction &&
                                    currentTransaction.status !== TRANSACTION_STATUS.OPEN &&
                                    !currentTransaction.rollback_of_id
                                );
                            },
                        },
                        {
                            name: 'Quote Conditions',
                            url: '/:transactionId/pipeline',
                            visible: ({ appContext: { currentTransaction }, tenantFlags }) => {
                                return tenantFlags.quote_conditions && currentTransaction && !currentTransaction.rollback_of_id;
                            },
                        },
                        {
                            name: 'Binding Requirements',
                            url: '/:transactionId/binding_requirements',
                            visible: ({ featureFlags, appContext: { currentTransaction }, tenantFlags }) => {
                                return (
                                    tenantFlags.binding_requirements &&
                                    !!featureFlags?.binding_requirements_v2 &&
                                    currentTransaction &&
                                    !currentTransaction.rollback_of_id
                                );
                            },
                        },
                        {
                            name: 'Policy Forms',
                            url: '/:transactionId/forms',
                            visible: ({ appContext: { currentTransaction }, tenantFlags }) => {
                                return (
                                    tenantFlags.policy_forms &&
                                    currentTransaction &&
                                    !currentTransaction.rollback_of_id &&
                                    !(CANCELLATION_TYPES as string[]).includes(currentTransaction.transaction_type)
                                );
                            },
                        },
                        {
                            name: 'Referral History',
                            url: '/:transactionId/referral_history',
                            visible: ({ appContext: { currentTransaction }, tenantFlags }) => {
                                return tenantFlags.referral_history && currentTransaction && !currentTransaction.rollback_of_id;
                            },
                        },
                        {
                            name: 'Bldg-specific Quote Cond',
                            url: '/:transactionId/quoteconditions',
                            visible: ({ appContext: { currentTransaction }, tenantFlags }) => {
                                return tenantFlags.quote_conditions && currentTransaction && !currentTransaction.rollback_of_id;
                            },
                        },
                        {
                            name: 'Blanket Limits',
                            url: '/:transactionId/limits',
                            visible: ({ featureFlags, appContext: { currentTransaction }, tenantFlags }) => {
                                return tenantFlags.blanket_limits && !!featureFlags.blanket_limits && currentTransaction && !currentTransaction.rollback_of_id;
                            },
                        },
                        {
                            name: 'Edit CO via Data Entry',
                            icon: 'link',
                            isExternal: true,
                            url: v1BaseUrl + '/submissions/new/:submissionId/coverage_options/:quoteId',
                            visible: ({ appContext: { currentTransaction } }) => {
                                return currentTransaction && !currentTransaction.rollback_of_id;
                            },
                        },
                    ],
                },
                {
                    name: 'Coverage Options',
                    visible: props => {
                        return showCoverageOptions(props.appContext) === true;
                    },
                    url: '/:submissionSlug/underwriting',
                    subItemIdGetter: 'quoteId',
                    subNavItems: [
                        {
                            name: 'Policy Terms',
                            url: '/:quoteId/policy_terms#overview',
                            visible: props => !!props.featureFlags.policy_coverage,
                            isScrollSpyEnabled: true,
                            subNavItems: Object.entries(POLICY_TERM_SECTION_IDS).map(([name, id]) => ({
                                name,
                                url: `#${id}`,
                                visible: props => usingPolicyCoverage(props) && !!props?.policyTermsSectionIds?.[id],
                            })),
                        },
                        {
                            name: 'Pricing',
                            url: '/:quoteId/pricing',
                            visible: props => props.tenantFlags.pricing,
                        },
                        {
                            name: 'PML/AAL Analysis',
                            url: '/:quoteId/modeling',
                            visible: props => props.tenantFlags.modeling,
                        },
                        {
                            name: 'Building Premiums',
                            url: '/:quoteId/premiums',
                            visible: props => props.tenantFlags.building_premiums,
                        },
                        {
                            name: 'TIV Guidelines',
                            url: '/:quoteId/guidelines',
                            visible: props => props.tenantFlags.tiv_guidelines,
                        },
                        {
                            name: 'Policy Forms',
                            url: '/:quoteId/forms',
                            visible: props => props.tenantFlags.policy_forms,
                        },
                        {
                            name: 'Referral History',
                            url: '/:quoteId/referral_history',
                            visible: props => props.tenantFlags.referral_history,
                        },
                        {
                            name: 'Quote Conditions',
                            url: '/:quoteId/pipeline',
                            visible: props => props.tenantFlags.quote_conditions,
                        },
                        {
                            name: 'Binding Requirements',
                            url: '/:quoteId/binding_requirements',
                            visible: props => props.tenantFlags.binding_requirements && !!props.featureFlags?.binding_requirements_v2,
                        },
                        {
                            name: 'Bldg-specific Quote Cond',
                            url: '/:quoteId/quoteconditions',
                            visible: props => props.tenantFlags.quote_conditions,
                        },
                        {
                            name: 'Blanket Limits',
                            url: '/:quoteId/limits',
                            visible: props => {
                                return props.tenantFlags.blanket_limits && !!props.featureFlags.blanket_limits;
                            },
                        },
                        {
                            name: 'Edit CO via Data Entry',
                            visible: true,
                            icon: 'link',
                            isExternal: true,
                            url: v1BaseUrl + '/submissions/new/:submissionId/coverage_options/:quoteId',
                        },
                    ],
                },
                {
                    name: 'Regional CAT Summary',
                    visible: props => props.tenantFlags.regional_cat_summary && hasSubmissionInfo(props.appContext),
                    url: '/:submissionSlug/regional-cat-summary',
                },
                {
                    name: props => {
                        return !hasSubmissionInfo(props.appContext) ? 'Select a submission' : '... Loading ...';
                    },
                    visible: props => {
                        return showCoverageOptions(props.appContext) === null;
                    },
                    url: '/',
                    subItemIdGetter: 'quoteId',
                    subNavItems: [],
                },
                {
                    name: 'Edit via Data Entry',
                    icon: 'link',
                    isExternal: true,
                    url: v1BaseUrl + '/submissions/new/:submissionId/coverage_options',
                    visible: props => {
                        const hasTransactionAndHasRollback = !!props.appContext.currentTransaction?.rollback_of_id;
                        return hasSubmissionInfo(props.appContext) && !hasTransactionAndHasRollback;
                    },
                },
            ],
        },
        {
            name: 'VIKI Search',
            visible: props => {
                const enabled = props?.featureFlags?.viki_search ?? false;
                return enabled && props.appContext.ENV.SITE_ACRONYM === 'VIKI';
            },
            url: `/search`,
        },
        {
            name: 'RAE Search',
            visible: props => {
                const enabled = props?.featureFlags?.viki_search ?? false;
                return enabled && props.appContext.ENV.SITE_ACRONYM === 'RAE';
            },
            url: `/search`,
        },
        {
            name: 'Tasks',
            url: '/tasks',
            visible: true,
        },
        {
            name: 'Glossary',
            url: '/glossary',
            visible: props => {
                return props.tenantFlags.glossary && props.me && props.me.has_analytics_role;
            },
        },
        {
            name: 'Actuarial Modules',
            url: '/actuarial',
            visible: props => props.tenantFlags.actuarial_modules && props.featureFlags.targetMultiples,
            subNavItems: [
                {
                    visible: props => props.featureFlags.targetMultiples,
                    name: 'Target Multiples',
                    url: '/target-multiples',
                },
                {
                    visible: props => props.featureFlags.targetMultiples,
                    name: 'Target Audit History',
                    url: '/target-multiple-audit-history',
                },
            ],
        },
    ];
};

export const ARCH_PML_AAL_UW_GUIDELINES_WIND_URL =
    'https://archcapservices.sharepoint.com/sites/ArchCommunicationTemplate/Underwriting%20Guidelines/Forms/AllItems.aspx?id=%2Fsites%2FArchCommunicationTemplate%2FUnderwriting%20Guidelines%2FNamed%20Windstorm%20Guidelines%2FUW%20Guidelines%20%2D%20Named%20Windstorm%2Epdf&parent=%2Fsites%2FArchCommunicationTemplate%2FUnderwriting%20Guidelines%2FNamed%20Windstorm%20Guidelines';

export const ARCH_PML_AAL_UW_GUIDELINES_EQ_URL =
    'https://archcapservices.sharepoint.com/sites/ArchCommunicationTemplate/Underwriting%20Guidelines/Forms/AllItems.aspx?id=%2Fsites%2FArchCommunicationTemplate%2FUnderwriting%20Guidelines%2FEarthquake%20Guidelines%2FEarthquake%2Epdf&viewid=736aaaab%2D6cc4%2D4c4c%2Dacac%2D8c7057ade1ca&parent=%2Fsites%2FArchCommunicationTemplate%2FUnderwriting%20Guidelines%2FEarthquake%20Guidelines';

export const ARCH_PML_AAL_UW_GUIDELINES_FLOOD_URL =
    'https://archcapservices.sharepoint.com/sites/ArchCommunicationTemplate/Underwriting%20Guidelines/Forms/AllItems.aspx?id=%2Fsites%2FArchCommunicationTemplate%2FUnderwriting%20Guidelines%2FFlood%20Guidelines%2FUnderwriting%20Guidelines%2DFlood%2Epdf&viewid=736aaaab%2D6cc4%2D4c4c%2Dacac%2D8c7057ade1ca&parent=%2Fsites%2FArchCommunicationTemplate%2FUnderwriting%20Guidelines%2FFlood%20Guidelines';
